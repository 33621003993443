
































































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import {
  Apiary, Hive, UserHive, User, UserDetails,
} from '@/types';

@Component({
  components: {
  },
})
export default class HiveDetailsDialog extends Vue {
  dialog = false
  loading = false

  users: UserDetails[] = [];

  // selectedHoneycombs = []

  result = {
    hives: 0,
    honeycombs: 0,
  }

  successDialog = false
  errorDialog = false

  headers = [
    { text: 'Adottante', value: 'adopter', sortable: false },
    { text: 'Favi', value: 'honeycombs', sortable: false },
    { text: 'Data adozione', value: 'adoptionDate', sortable: false },
    { text: 'Data scadenza', value: 'expiryDate', sortable: false },
    { text: 'Miele consegnato', value: 'delivered', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  get value(): boolean {
    return this.$store.getters.hiveDetailsDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get currentUserId(): string | null {
    return this.currentUser.uid ?? null;
  }

  get apiary(): Apiary | null {
    return this.$store.getters.apiary;
  }

  get apiaryImage(): string {
    return this.apiary?.headerImage
      ? `/apiaries/${this.apiary.headerImage}`
      : '/apiaries/fake-header-01.jpg';
  }

  get apiaryName(): string {
    return this.apiary?.name ?? '';
  }

  get apiaryLocation(): string {
    return this.apiary?.location ?? 'Sconosciuta';
  }

  get apiaryBeekeeperName(): string {
    return this.apiary?.beekeeper.name ?? 'Sconosciuto';
  }

  get apiaryHoney(): string[] {
    return this.apiary?.honey ?? [];
  }

  get apiaryFlora(): string[] {
    return this.apiary?.flora ?? [];
  }

  get hive(): Hive | null {
    return this.$store.getters.hive;
  }

  get hiveImage(): string {
    return this.hive?.photo
      ? `/hives/${this.hive.photo}`
      : '/apiaries/fake-header-01.jpg';
  }

  get hiveName(): string {
    return this.hive?.name ?? '';
  }

  get hivePopulation(): string {
    return this.hive?.population ?? 'Sconosciuta';
  }

  get hiveBehavior(): string {
    return this.hive?.behavior ?? 'Sconosciuto';
  }

  get hiveProductivity(): string {
    return this.hive?.productivity ?? 'Sconosciuta';
  }

  get honeycombsAvailable(): number {
    return this.hive?.honeycombsAvailable ?? 0;
  }

  get honeycombsCount(): number {
    return this.hive?.honeycombsCount ?? 0;
  }

  get hiveOwners(): string[] {
    return this.hive?.owners ?? [];
  }

  get adoptions(): string[] {
    return this.hiveOwners;
  }

  userDetails(id: string): UserDetails | null {
    return this.users.find((u) => u.id === id) ?? null;
  }

  userFullName(id: string): string {
    return `${this.userDetails(id)?.firstName} ${this.userDetails(id)?.lastName}`;
  }

  userEmail(id: string): string {
    return `${this.userDetails(id)?.email}`;
  }

  userHiveAdoption(id: string): UserHive | null {
    if (!this.hive?.id) return null;

    const userDetails = this.userDetails(id);
    const userDetailsHives = userDetails?.hives ?? {};

    if (userDetailsHives[this.hive.id] === undefined) return null;

    const hiveAdoption = userDetailsHives[this.hive.id];

    return {
      apiary: hiveAdoption.apiary,
      hive: this.hive.id,
      honeycombs: hiveAdoption.honeycombs,
      delivered: hiveAdoption.delivered,
    };
  }

  userDelivered(id: string): boolean {
    return this.userHiveAdoption(id)?.delivered ?? false;
  }

  userHoneycombs(id: string): number {
    return this.userHiveAdoption(id)?.honeycombs ?? 0;
  }

  userAdoptionDate(id: string): string {
    return '???';
  }

  userExpiryDate(id: string): string {
    return '???';
  }

  @Watch('value')
  async onValueChanged(val: boolean) {
    const debugPrefix = 'HiveDetailsDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      this.loading = true;

      // Recupera l'elenco degli utenti
      this.users = await this.$store.dispatch('getUsers');

      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'HiveDetailsDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'hiveDetails');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }

  onMap() {
    console.log('HiveDetailsDialog: onMap()'); // eslint-disable-line no-console

    this.$router.push({
      name: 'Map',
    });
  }
}
